import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';

class Logo extends PureComponent {
    static propTypes = {
        content: PropTypes.object.isRequired,
    };

    render() {
        const {
            content: { logoImg, logoLink, alt, logoWidth },
        } = this.props;

        return logoImg ? (
            <h1 className={styles.logo}>
                {logoLink ? (
                    <a href={logoLink}>
                        <img src={logoImg} alt={alt} width={logoWidth} />
                    </a>
                ) : (
                    <img src={logoImg} alt={alt} width={logoWidth} />
                )}
            </h1>
        ) : null;
    }
}

export default errorBoundary(Logo);
