import React from 'react';
import ReactDOM from 'react-dom';
import 'core-js';
import '../commoncss/reset.css';
import './reset.css';
import Layout from './layout';

/* eslint-disable no-undef */
ReactDOM.render(<Layout />, document.getElementById('root'));
/* eslint-enable no-undef */
