import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './inputItem.css';
import { LoginContext } from '../store/loginContext';
import stylesAni from '../../../common/animation.css';

class InputItem extends PureComponent {
    static propTypes = {
        labelName: PropTypes.string,
        name: PropTypes.string,
        placeholder: PropTypes.string,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        getMsgCode: PropTypes.func,
        error: PropTypes.bool,
        fieldsError: PropTypes.object,
        clearErrors: PropTypes.func,
        inputType: PropTypes.string,
        labelSeparete: PropTypes.bool,
    };

    handleInputChange = e => {
        const { onChange } = this.props;

        onChange(e.target.value);
    };

    handleInputOnBlur = e => {
        const { onBlur } = this.props;

        onBlur(e);
    };

    render() {
        const {
            labelName,
            name,
            placeholder,
            error,
            fieldsError,
            clearErrors,
            getMsgCode,
            inputType,
            labelSeparete,
        } = this.props;

        return (
            <div className={styles.inputItem}>
                <label>
                    {labelName ? (
                        <span className={`${styles.label} ${labelSeparete ? styles.labelSeparete : null}`}>
                            {labelName}
                        </span>
                    ) : null}
                    <div className={styles.itemContent}>
                        {error && fieldsError[name] ? (
                            <div className={`${styles.errorWarn} ${stylesAni.shake}`} onClick={() => clearErrors(name)}>
                                {fieldsError[name]}
                            </div>
                        ) : (
                            <input
                                className={styles.commonInput}
                                type={inputType || 'text'}
                                placeholder={placeholder}
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputOnBlur}
                            />
                        )}
                    </div>
                </label>
                {name === 'captcha_code' ? (
                    <LoginContext.Consumer>
                        {context => (
                            <div
                                className={styles.captchaImg}
                                onClick={() => context.dispatch({ type: 'refreshCaptchaImg' })}>
                                <img src={context.captchaImg} data-id={context.captchaId} />
                            </div>
                        )}
                    </LoginContext.Consumer>
                ) : null}
                {name === 'smscode' ? (
                    <LoginContext.Consumer>
                        {context => {
                            return context.smsSendStatus ? (
                                <div className={`${styles.sendMsgBtn} ${styles.smsSending}`}>
                                    已发送（{context.smsInterval}）
                                </div>
                            ) : (
                                <div
                                    className={`${styles.sendMsgBtn} ${styles.sendMsg}`}
                                    onClick={() => getMsgCode(context)}>
                                    {context.hasSendSms ? '重新获取' : '获取短信验证码'}
                                </div>
                            );
                        }}
                    </LoginContext.Consumer>
                ) : null}
            </div>
        );
    }
}

export default errorBoundary(InputItem);
