const search = window.location.search;

export const formatSearchParams = search => {
    const _search = search.split('?')[1];

    const res = {};

    _search.split('&').forEach(item => {
        const itemParams = item.split('=');

        res[itemParams[0]] = itemParams[1];
    });

    return res;
};

let cb = '';

if (search) {
    const searchParams = formatSearchParams(search);

    if (searchParams.cb) {
        cb = searchParams.cb;
    }
}

const pagesConfig = {
    '0': {
        typeName: '默认',
        logoImg: 'https://y0.ifengimg.com/ifeng/index/20150821/ifengNewLogo.png',
        logoLink: 'http://www.ifeng.com',
        alt: '凤凰新媒体',
        cb: 'http://www.ifeng.com',
        findPasscb: 'https://id.ifeng.com/muser/login',
        ads_img: '',
        pageBgColor: '#fff', // 页面背景颜色
        login3: ['qq', 'weibo'],
        register_cb: true,
        register: [
            `<a href="https://user.ifeng.com/register?cb=${cb ||
                'http%3A%2F%2Fwww.ifeng.com'}" style="width: 50%;float: left;text-align: center;color:#043f8f;line-height:20px;font-size:14px;">立即注册</a>`,
            '<a href="https://user.ifeng.com/findpass" style="width: 50%;float: left;text-align: center;color:#043f8f;font-size:14px;">忘记密码</a>',
        ],
        custom_css: '',
        login_tab: 3, // 1为账密登录，2为短信免密登录（扩展按照2的n次方扩展）
        login_tab_first: 1, // 1为账密登录，2为短信免密登录（扩展按照2的n次方扩展）
    },
    '1': {
        typeName: '凤凰博报',
        logoImg: 'https://y0.ifengimg.com/a/2015/0714/logo_blog.gif',
        logoLink: 'http://blog.ifeng.com/',
        alt: '凤凰博报',
        cb: 'http://blog.ifeng.com/',
        ads_img: '',
        submitBtnBgColor: '#3f6275',
        login3: ['qq', 'weibo'],
        comefrom: 2, // 博客对应的用户体系的id为2
        login_tab: 3, // 1为账密登录，2为短信免密登录（扩展按照2的n次方扩展）
        login_tab_first: 2, // 1为账密登录，2为短信免密登录（扩展按照2的n次方扩展）
    },
    '2': {
        typeName: '理财超市',
        logoImg: 'https://y1.ifengimg.com/a/2015/0514/logo.png',
        alt: '理财超市',
        cb: 'http://18.ifeng.com/',
        ads_img: '',
        submitBtnBgColor: '#DD300E',
        login3: ['qq', 'weibo'],
        register: [
            '<a href="http://18.ifeng.com/weixin/signin" style="color:#043f8f;width: 100%;float:left;text-align: center;"><u>免费开户</u></a>',
        ],
        comefrom: 256, // 财经对应的用户体系的id为256
        login_tab: 1, // 1为账密登录，2为短信免密登录（扩展按照2的n次方扩展）
        login_tab_first: 1, // 1为账密登录，2为短信免密登录（扩展按照2的n次方扩展）
        hide_login_tab: true,
    },
    '3': {
        typeName: '炒股大赛',
        alt: '炒股大赛',
        cb: 'http://ds.ifeng.com/h5/',
        ads_img: '',
        submitBtnBgColor: '#EE3F0A',
        login3: ['qq', 'weibo'],
        register: [
            '<a href="http://ds.ifeng.com/h5/user/reg/" style="color:#043f8f;width: 100%;float:left;text-align: center;"><u>用户注册</u></a>',
        ],
    },
    '4': {
        typeName: '时尚APP',
        logoImg: 'https://p1.ifengimg.com/a/2016/0802/shizhuang.png',
        logoWidth: '86',
        alt: '时尚APP',
        cb: 'http://shizhuang.ifeng.com',
        findPasscb: 'http://shizhuang.ifeng.com/index/findPassSuccess',
        ads_img: '',
        submitBtnBgColor: '#fcc700',
        pageBgColor: '#252525',
        submitBtnColor: '#333333',
        login3: ['qq', 'weibo'],
        register: [
            `<a href="https://user.ifeng.com/register?cb=${cb ||
                'http%3A%2F%2Fwww.ifeng.com'}" style="color:#043f8f;width: 100%;float:left;text-align: center;"><u>用户注册</u></a>`,
        ],
        comefrom: 256, // 财经对应的用户体系的id为256
    },
    '5': {
        typeName: '凤凰视频客户端',
        logoImg: 'https://p1.ifengimg.com/a/2016/0802/video-logo-new.png',
        alt: '凤凰视频客户端',
        cb: '',
        findPasscb: '',
        ads_img: '',
        login3: ['qq', 'weibo'],
        register: [
            '<a href="https://user.ifeng.com/register?id=5" style="color:#043f8f;width: 100%;float:left;text-align: center;"><u>用户注册</u></a>',
        ],
        comefrom: 19, // 视频客户端对应的用户体系的id为19
    },
    '6': {
        typeName: '荐闻社',
        // logoImg: 'http://d.ifengimg.com/q75/img.ugc.ifeng.com/ugc/video/20160310/image/16/0bMcxqLu02U.png',
        alt: '荐闻社',
        cb: '',
        findPasscb: 'http://i.t.ifeng.com/public/findpasssuccess',
        ads_img: '',
        login3: ['qq', 'weibo'],
        submitBtnBgColor: '#46cf98',
        submitBtnColor: '#f6f6f6',
        register: [
            '<a href="https://user.ifeng.com/register?id=6" style="color:#043f8f;width: 100%;float:left;text-align: center;"><u>用户注册</u></a>',
        ],
        login_tab: 2, // 1为账密登录，2为短信免密登录（扩展按照2的n次方扩展）
        login_tab_first: 2, // 1为账密登录，2为短信免密登录（扩展按照2的n次方扩展）
    },
    '7': {
        typeName: '手凤',
        logoImg: 'https://y0.ifengimg.com/ifeng/index/20150821/ifengNewLogo.png',
        logoLink: 'http://www.ifeng.com',
        alt: '凤凰新媒体',
        cb: 'http://i.ifeng.com',
        findPasscb: 'https://id.ifeng.com/muser/login',
        ads_img: '',
        // 提交按钮颜色
        pageBgColor: '#F8F8F8', // 页面背景颜色
        login3: ['qq', 'weibo'],
        register: [
            `<a href="https://user.ifeng.com/register?id=7&cb=${cb ||
                'http://i.ifeng.com'}" style="width: 50%;float: left;text-align: center;color:#043f8f;line-height:20px;font-size:14px;">立即注册</a>`,
            '<a href="https://user.ifeng.com/findpass" style="width: 50%;float: left;text-align: center;color:#043f8f;font-size:14px;">忘记密码</a>',
        ],
        comefrom: 33, // 手凤对应的用户体系的id为33
    },
    '8': {
        typeName: '甄选',
        logoImg: 'https://y0.ifengimg.com/imall/static/161129/zx_logo.png',
        logoLink: 'http://imall.ifeng.com',
        alt: '凤凰甄选',
        cb: 'http://imall.ifeng.com',
        findPasscb: 'https://id.ifeng.com/muser/login',
        ads_img: '',
        submitBtnBgColor: '#3d8397', // 提交按钮颜色
        pageBgColor: '#F8F8F8', // 页面背景颜色
        login3: {
            weibo:
                '<div class="num3"><a class="wb_btn a3 bg_weixin" href="https://open.weixin.qq.com/connect/qrconnect?appid=wx10ce490118943a33&redirect_uri=http%3A%2F%2Fid.ifeng.com%2Fcallback%2Fweixin###cb###&response_type=code&response_type=code&scope=snsapi_login&response_type=code&scope=snsapi_login&state=0"&response_type=code&scope=snsapi_login&state=0"&gt;&response_type=code&scope=snsapi_login&state=0"><img src="https://y0.ifengimg.com/imall/static/161129/wb.png" alt="微博登陆"></a></div>',
            qq:
                '<div class="num3"><a class="wb_btn a3 bg_weixin" href="https://open.weixin.qq.com/connect/qrconnect?appid=wx10ce490118943a33&redirect_uri=http%3A%2F%2Fid.ifeng.com%2Fcallback%2Fweixin###cb###&response_type=code&response_type=code&scope=snsapi_login&response_type=code&scope=snsapi_login&state=0"&response_type=code&scope=snsapi_login&state=0"&gt;&response_type=code&scope=snsapi_login&state=0"><img src="https://y0.ifengimg.com/imall/static/161129/qq.png" alt="QQ登陆"></a></div>',
        },
        register_cb: true,
        register: [
            `<a href="https://user.ifeng.com/register?id=8&cb=${cb ||
                'http://imall.ifeng.com'}" style="width: 50%;float: left;text-align: center;color:#043f8f;line-height:20px;font-size:14px;">立即注册</a>`,
            '<a href="https://user.ifeng.com/findpass" style="width: 50%;float: left;text-align: center;color:#043f8f;font-size:14px;">忘记密码</a>',
        ],

        comefrom: 34, // 甄选对应的用户体系的id为34
    },
    '9': {
        typeName: '风直播',
        logoImg: 'https://p3.ifengimg.com/a/2017/1026/092e9f20aa8326fsize10_w212_h105.png',
        alt: '风直播',
        cb: 'http://izhibo.ifeng.com/callback/nocontent',
        findPasscb: 'https://id.ifeng.com/muser/login?id=9&cb=http://izhibo.ifeng.com/callback/nocontent',
        ads_img: '',
        submitBtnBgColor: '#ff4c4c', // 提交按钮颜色
        pageBgColor: '#F8F8F8', // 页面背景颜色
        register_cb: true,
        register: [
            `<a href="https://user.ifeng.com/register?id=9&cb=${cb ||
                'http://izhibo.ifeng.com/callback/nocontent'}" style="width: 50%;float: left;text-align: center;color:#043f8f;line-height:20px;font-size:14px;">立即注册</a>`,
            '<a href="https://user.ifeng.com/findpass" style="width: 50%;float: left;text-align: center;color:#043f8f;font-size:14px;">忘记密码</a>',
        ],

        comefrom: 36, // 风直播对应的用户体系的id为36
        // 'custom_css' : '<link href="https://p0.ifengimg.com/a/2017/1102/e222338eac9d46a.css" rel="stylesheet" type="text/css" />',
    },
    '10': {
        typeName: '炒股大赛 客户端迁移',
        cb: 'http://ds.ifeng.com/',
        ads_img: '',
        submitBtnBgColor: '#EE3F0A',
        login3: {
            /* 一行三个请加<div class="num3"></div>这个标签嵌套
                'weixin':'<div class="num3"><a class="wb_btn a3 bg_weixin" href="https://open.weixin.qq.com/connect/qrconnect?appid=wx10ce490118943a33&redirect_uri=http%3A%2F%2Fid.ifeng.com%2Fcallback%2Fweixin###cb###&response_type=code&scope=snsapi_login&state=0"><img src="//id.ifeng.com/static/m/images/weixin.png" alt="微信登陆"></a></div>',
            */
            qq:
                '<a class="qzone_btn bg_blue" href="https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=100514926&state=8bc42edbdbd6e37dedd1cee53f009b7f&scope=get_user_info,add_share,list_album,add_album,upload_pic,add_topic,add_one_blog,add_weibo,check_page_fans,add_t,add_pic_t,del_t,get_repost_list,get_info,get_other_info,get_fanslist,get_idolist,add_idol,del_idol,get_tenpay_addr&redirect_uri=https%3A%2F%2Fid.ifeng.com%2Fcallback%2Fqzone###cb###"><img src="//x0.ifengimg.com/ucms/2020_45/08218AA1662E24A303D931A8A82F009EB24B24E5_w50_h50.png" alt="QQ登录"></a>',
            weibo:
                '<a class="wb_btn bg_red" href="https://api.weibo.com/oauth2/authorize?client_id=1073104718&response_type=code&redirect_uri=http%3A%2F%2Fid.ifeng.com%2Fcallback%2Fsina###cb###"><img src="//x0.ifengimg.com/ucms/2020_45/C56003066564545A7AB5E2F7DE43805E9589BDD0_w50_h50.png" alt="微博登陆"></a>',
            // 'weixin':'<div class="num3"><a class="wx_btn bg_weixin" href="https://open.weixin.qq.com/connect/qrconnect?appid=wx10ce490118943a33&response_type=code&scope=snsapi_login&state=0&redirect_uri=http%3A%2F%2Fid.ifeng.com%2Fcallback%2Fweixin###cb###"><img src="//id.ifeng.com/static/m/images/weixin.png" alt="微信登陆"></a></div>',
        },
        register: [
            `<a href="https://user.ifeng.com/register?id=10&cb=${cb ||
                'http://ds.ifeng.com/h5/index/setCjsid'}" style="color:#043f8f;width: 100%;float:left;text-align: center;"><u>用户注册</u></a>`,
        ],
        login_button_text: '凤凰财经炒股大赛帐号验证',
    },
    '11': {
        typeName: '汽车APP',
        alt: '汽车APP',
        cb: 'http://i.ifeng.com/',
        ads_img: '',
        submitBtnBgColor: '#EE3F0A',
        login3: ['qq', 'weibo'],
        register: [
            '<a href="http://i.ifeng.com/" style="color:#043f8f;width: 100%;float:left;text-align: center;"><u>用户注册</u></a>',
        ],
    },
    '12': {
        typeName: '知之（新闻客户端）',
        logoImg: 'https://p0.ifengimg.com/a/2017/1130/d980496f969e56bsize36_w579_h144.png',
        alt: '凤凰新闻客户端',
        cb: 'http://i.ifeng.com/',
        ads_img: '',
        submitBtnBgColor: '#EE3F0A',

        comefrom: 7, // 新闻客户端的用户体系的id为7\
        login_tab: 2, // 1为账密登录，2为短信免密登录（扩展按照2的n次方扩展）
        login_tab_first: 2, // 1为账密登录，2为短信免密登录（扩展按照2的n次方扩展）
        authcode: false, // 首次进入是否需展示验证码 默认true 展示
    },
    '13': {
        typeName: '读书',
        logoImg: 'https://y0.ifengimg.com/ifeng/index/20150821/ifengNewLogo.png',
        logoLink: 'http://wap.yc.ifeng.com',
        alt: '凤凰新媒体',
        cb: 'http://i.ifeng.com/',
        findPasscb: 'https://id.ifeng.com/muser/login',
        ads_img: '',
        // 提交按钮颜色
        pageBgColor: '#fff', // 页面背景颜色
        login3: {
            qq:
                '<a class="qzone_btn bg_blue" href="https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=100514926&state=8bc42edbdbd6e37dedd1cee53f009b7f&scope=get_user_info,add_share,list_album,add_album,upload_pic,add_topic,add_one_blog,add_weibo,check_page_fans,add_t,add_pic_t,del_t,get_repost_list,get_info,get_other_info,get_fanslist,get_idolist,add_idol,del_idol,get_tenpay_addr&redirect_uri=https%3A%2F%2Fid.ifeng.com%2Fcallback%2Fqzone###cb###"><img src="//x0.ifengimg.com/ucms/2020_45/08218AA1662E24A303D931A8A82F009EB24B24E5_w50_h50.png" alt="QQ登录"></a>',
            weibo:
                '<a class="wb_btn bg_red" href="https://api.weibo.com/oauth2/authorize?client_id=1073104718&response_type=code&redirect_uri=http%3A%2F%2Fid.ifeng.com%2Fcallback%2Fsina###cb###"><img src="//x0.ifengimg.com/ucms/2020_45/C56003066564545A7AB5E2F7DE43805E9589BDD0_w50_h50.png" alt="微博登陆"></a>',
        },
        register: [
            `<a href="https://user.ifeng.com/register?cb=${cb ||
                'http://i.ifeng.com/'}" style="width: 50%;float: left;text-align: center;color:#043f8f;line-height:20px;font-size:14px;">立即注册</a>`,
            '<a href="https://user.ifeng.com/findpass" style="width: 50%;float: left;text-align: center;color:#043f8f;font-size:14px;">忘记密码</a>',
        ],

        custom_css: '',
        comefrom: 5, // 新闻客户端的用户体系的id为7
        login_tab: 3, // 1为账密登录，2为短信免密登录（扩展按照2的n次方扩展）
        login_tab_first: 2, // 1为账密登录，2为短信免密登录（扩展按照2的n次方扩展）
    },
};

const login3 = {
    qq:
        '<a class="qzone_btn" href="https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=100514926&state=8bc42edbdbd6e37dedd1cee53f009b7f&scope=get_user_info,add_share,list_album,add_album,upload_pic,add_topic,add_one_blog,add_weibo,check_page_fans,add_t,add_pic_t,del_t,get_repost_list,get_info,get_other_info,get_fanslist,get_idolist,add_idol,del_idol,get_tenpay_addr&redirect_uri=https%3A%2F%2Fid.ifeng.com%2Fcallback%2Fqzone###cb###"><img src="https://p2.ifengimg.com/a/2017/1120/qq.png" alt="QQ登录"></a>',
    weibo:
        '<a class="wb_btn" href="https://api.weibo.com/oauth2/authorize?client_id=1073104718&response_type=code&redirect_uri=http%3A%2F%2Fid.ifeng.com%2Fcallback%2Fsina###cb###"><img src="https://p0.ifengimg.com/a/2017/1120/sina.png" alt="微博登陆"></a>',
    weixin:
        '<a class="wx_btn bg_weixin" href="https://open.weixin.qq.com/connect/qrconnect?appid=wx10ce490118943a33&response_type=code&scope=snsapi_login&state=0&redirect_uri=http%3A%2F%2Fid.ifeng.com%2Fcallback%2Fweixin###cb###"><img src="//id.ifeng.com/static/m/images/weixin.png" alt="微信登陆"></a>',
    // 'qqweibo' : '<a class="qq_btn bg_blue2" href="http://ui.ptlogin2.qq.com/cgi-bin/login?style=9&appid=46000101&daid=6&pt_no_auth=1&s_url=https%3A%2F%2Fopen.t.qq.com%2Fcgi-bin%2Foauth2%2Fauthorize%3Fclient_id%3D801229609%26response_type%3Dcode%26redirect_uri%3Dhttp%253A%252F%252Fid.ifeng.com%252Fcallback%252Ftweibo###cb###%26oauthtoken%3D%26checkStatus%3Dyes%26appfrom%3Dios%26g_tk%3D1373177269%26sessionKey%3Dedf3cc34018f409e9eb6b35e955dcc2d%26checkType%3DshowAuth%26state%3D&low_login=1&hln_css=http%3A%2F%2Fimg1.gtimg.com%2Fapp%2Fpics%2Fhv1%2F72%2F133%2F1425%2F92694612.png&hln_copyright=腾讯微博开放平台"><img src="//x0.ifengimg.com/ucms/2020_45/08218AA1662E24A303D931A8A82F009EB24B24E5_w50_h50.png" alt="腾讯微博">腾讯微博</a>',
};

export { pagesConfig, login3 };
