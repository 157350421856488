import React from 'react';
import PropTypes from 'prop-types';

import { trimFun } from '../utils';

// 表单项
class FormItem extends React.Component {
    static propTypes = {
        children: PropTypes.object,
        name: PropTypes.string,
        placeholder: PropTypes.string,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onError: PropTypes.func,
        rules: PropTypes.array,
        validateTrigger: PropTypes.oneOf(['change', 'blur']),
        clearErrors: PropTypes.func,
        callbackRules: PropTypes.func,
        fieldsError: PropTypes.object,
    };

    state = {
        onChange: () => {},
        onError: () => {},
        hasError: false,
        parentError: false,
        value: '',
        error: '',
        rules: this.props.rules,
    };

    static getDerivedStateFromProps = (props, state) => {
        const resError = {};
        let resHasError = {};
        let resRules = {};

        if (props.fieldsError && props.name && !!props.fieldsError[props.name]) {
            resHasError = { parentError: props.fieldsError[props.name] };
        } else {
            resHasError = { parentError: '' };
        }

        if (props.rules !== state.rules) {
            resRules = { rules: props.rules };
        }

        return { ...resError, ...resHasError, ...resRules };
    };

    static defaultProps = {
        rules: [],
        validateTrigger: 'blur',
    };

    componentDidMount = () => {
        const { callbackRules, name, rules } = this.props;

        if (callbackRules) {
            callbackRules(name, rules);
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.rules !== this.state.rules) {
            const { callbackRules } = this.props;

            if (callbackRules) {
                callbackRules(this.props.name, this.state.rules);
            }
        }
    };

    // 接收表单值
    handleChange = value => {
        const { name, onChange, validateTrigger } = this.props;

        this.setState({
            value,
        });

        if (validateTrigger === 'change') {
            this.handleCheck(value, validateTrigger);
        }

        if (onChange) {
            onChange({ fieldsName: name, fieldsValue: value });
        }
    };

    // blur触发时
    handleBlur = e => {
        const value = e.currentTarget.value;

        this.setState({
            value,
        });

        const { name, onBlur, validateTrigger } = this.props;

        this.handleCheck(value, validateTrigger);
        // blur时必须处理必填函数

        if (onBlur) {
            onBlur({ fieldsName: name, fieldsValue: value });
        }
    };

    // 处理错误信息
    handleError = () => {
        const { name, onError } = this.props;
        const { error } = this.state;

        if (onError) {
            onError({ fieldsName: name, fieldsError: error });
        }
    };

    // 错误回调
    errCallBack = message => {
        this.setState(
            {
                error: message,
                hasError: true,
            },
            () => {
                this.handleError();
            },
        );
    };

    // 清理错误信息
    clearErrors = name => {
        const { clearErrors } = this.props;

        this.setState(
            {
                error: '',
                hasError: false,
            },
            () => {
                if (clearErrors) {
                    clearErrors(name);
                }
            },
        );
    };

    // 处理校验
    handleCheck = () => {
        const { value, rules } = this.state;
        const { name } = this.props;

        for (let i = 0; i < rules.length; i++) {
            const item = rules[i];

            // 必填项
            if (item.hasOwnProperty('required') && item.required === true) {
                if (!value || (value && !trimFun(value))) {
                    this.errCallBack(item.message);
                    console.warn(`fieldsError:[${name}] ===== ${item.message}`);
                    break;
                } else {
                    this.clearErrors(name);
                }
            }

            // 正则校验
            if (item.hasOwnProperty('pattern')) {
                const pattern = item.pattern;

                if (!pattern.test(value)) {
                    this.errCallBack(item.message);
                    console.warn(`fieldsError:[${name}] ===== ${item.message}`);
                    break;
                } else {
                    this.clearErrors(name);
                }
            }

            // 自定义检验函数
            if (item.hasOwnProperty('validator')) {
                let flag = false;
                const validator = item.validator;
                const callBack = message => {
                    if (message) {
                        this.errCallBack(message);
                        console.warn(`fieldsError:[${name}] ===== ${message}`);
                        flag = true;
                    } else {
                        flag = false;
                        this.clearErrors(name);
                    }
                };

                validator(value, callBack);
                if (flag) {
                    break;
                }
            }
        }
    };

    render() {
        const { value, parentError, error } = this.state;
        const { name, placeholder } = this.props;

        return React.cloneElement(this.props.children, {
            ...this.props,
            name,
            placeholder,
            onChange: this.handleChange,
            onBlur: this.handleBlur,
            value,
            error: !!error || !!parentError,
            errorMessage: error || parentError,
        });
    }
}

export { FormItem };
export default FormItem;
