import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';

class Tabs extends PureComponent {
    static propTypes = {
        config: PropTypes.object,
        current: PropTypes.number,
        handleChange: PropTypes.func,
    };

    renderTabs = () => {
        const { config: { login_tab = 3 } = {}, current, handleChange } = this.props;

        // 1为账密登录
        if (login_tab === 1) {
            return null;
            // (
            //     <li id="accountLogin" className={styles.active_tab}>
            //         <span>账号登录</span>
            //     </li>
            // );
        } else if (login_tab === 2) {
            return null;
            // (
            //     <li id="codeLogin" className={styles.active_tab}>
            //         <span>手机登录</span>
            //     </li>
            // );
        } else if (login_tab === 3) {
            return (
                <Fragment>
                    <li
                        id="accountLogin"
                        className={current === 1 ? styles.active_tab : null}
                        onClick={() => handleChange({ type: 'changeTab', payload: 1 })}>
                        <span>账号登录</span>
                    </li>
                    <li
                        id="codeLogin"
                        className={current === 2 ? styles.active_tab : null}
                        onClick={() => handleChange({ type: 'changeTab', payload: 2 })}>
                        <span>手机登录</span>
                    </li>
                </Fragment>
            );
        }
    };

    render() {
        return <ul className={styles.tab}>{this.renderTabs()}</ul>;
    }
}

export default errorBoundary(Tabs);
