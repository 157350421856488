import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import InputItem from './inputItem';
import { FormItem } from '../../../../components/formReducer';
import LoginContext from '../store/loginContext';

class InputGroup extends PureComponent {
    static propTypes = {
        current: PropTypes.number,
    };

    constructor(props) {
        super(props);

        this.state = {
            current: props.current,
            fieldsValue: {},
            fieldsError: {},
            error: '',
        };
    }

    // 接收表单返回值
    handleChange = value => {
        const { fieldsValue } = this.state;

        fieldsValue[value.fieldsName] = value.fieldsValue;
        const newfieldsValue = JSON.parse(JSON.stringify(fieldsValue));

        this.setState({
            fieldsValue: newfieldsValue,
        });
    };

    // 接收错误信息
    handleError = error => {
        const { fieldsError } = this.state;

        fieldsError[error.fieldsName] = error.fieldsError;
        const newfieldsError = JSON.parse(JSON.stringify(fieldsError));

        this.setState({
            error,
            fieldsError: newfieldsError,
        });
    };

    // 清除指定错误信息
    clearErrors = name => {
        const { fieldsError, error } = this.state;
        const newFieldsError = JSON.parse(JSON.stringify(fieldsError));

        if (newFieldsError.hasOwnProperty(name)) {
            delete newFieldsError[name];
        }
        this.setState(
            {
                fieldsError: newFieldsError,
            },
            () => {
                if (error.fieldsName === name) {
                    this.setState({
                        error: '',
                    });
                }
            },
        );
    };

    fixErrorField = (name, errorMsg) => {
        const { fieldsValue } = this.state;

        this.setState(
            {
                fieldsValue: {
                    ...fieldsValue,
                    [name]: '',
                },
            },
            () => {
                this.handleError(errorMsg);
            },
        );
    };

    getMsgCode = providerContext => {
        const { fieldsValue, fieldsError } = this.state;

        if (!fieldsValue['phone']) {
            this.fixErrorField('phone', {
                fieldsName: 'phone',
                fieldsError: '请输入11位手机号',
            });
        } else if (fieldsError['phone']) {
            this.fixErrorField('phone', {
                fieldsName: 'phone',
                fieldsError: fieldsError['phone'],
            });
        } else if (!fieldsValue['captcha_code']) {
            this.fixErrorField('captcha_code', {
                fieldsName: 'captcha_code',
                fieldsError: '请输入验证码',
            });
        } else {
            const { dispatch, captchaId } = providerContext;

            dispatch({
                type: 'sendSms',
                payload: {
                    mobile: fieldsValue['phone'],
                    smsform: 1,
                    captcha_id: captchaId,
                    captcha_code: fieldsValue['captcha_code'],
                },
                errorCallback: eRes => {
                    if (eRes.code === 'IllegalMobile') {
                        this.fixErrorField('phone', {
                            fieldsName: 'phone',
                            fieldsError: '手机号格式不正确',
                        });
                    } else if (
                        [
                            'CaptchaRequired',
                            'SendSMSTooManyPerHour',
                            'SendSMSTooManyPerYear',
                            'SendSMSTooManyPerMonth',
                            'IPNotInMainland',
                        ].includes(eRes.code)
                    ) {
                        this.fixErrorField('captcha_code', {
                            fieldsName: 'captcha_code',
                            fieldsError: eRes.tips,
                        });
                    } else if (eRes.code === 'CaptchaNotMatch') {
                        this.fixErrorField('captcha_code', {
                            fieldsName: 'captcha_code',
                            fieldsError: '图片验证码不匹配',
                        });
                    } else if (eRes.code === 'SendSMSTooManyPerDay') {
                        alert(eRes.tips || '24小时内发送验证码太频繁');
                    } else if (eRes.code === 'SendSMSTooManyPerMinute') {
                        alert(eRes.tips || '1分钟内发送验证码太频繁');
                    } else if (eRes.code === 'Internal') {
                        alert(eRes.tips || '内部错误');
                    } else if (eRes.code === 'Unkown') {
                        alert(eRes.tips || '未知错误，请尝试更换手机号');
                    }
                },
            });
        }
    };

    submitLogin = (dispatch, captchaId) => {
        const { current } = this.props;
        const { fieldsValue, fieldsError } = this.state;

        if (current === 1) {
            if (!fieldsValue['account']) {
                this.fixErrorField('account', {
                    fieldsName: 'account',
                    fieldsError: '请输入用户名/邮箱/手机号',
                });
            } else if (!fieldsValue['password']) {
                this.fixErrorField('password', {
                    fieldsName: 'password',
                    fieldsError: '请输入密码',
                });
            }

            if (JSON.stringify(fieldsError) === '{}') {
                dispatch({
                    type: 'submitLogin',
                    loginType: 1, // 登录类型 0 手机号 1 账密
                    payload: {
                        // type: 1,
                        account: fieldsValue['account'],
                        password: fieldsValue['password'],
                        captcha_code: fieldsValue['captcha_code'],
                        captcha_id: captchaId,
                    },
                    errorCallback: eRes => {
                        if (eRes.code === 'CaptchaNotMatch') {
                            this.fixErrorField('captcha_code', {
                                fieldsName: 'captcha_code',
                                fieldsError: '图片验证码不匹配',
                            });
                        } else {
                            this.fixErrorField('password', {
                                fieldsName: 'password',
                                fieldsError: eRes.tips,
                            });
                        }
                    },
                });
            }
        } else if (current === 2) {
            if (!fieldsValue['phone']) {
                this.fixErrorField('phone', {
                    fieldsName: 'phone',
                    fieldsError: '请输入11位手机号',
                });
            } else if (!fieldsValue['captcha_code']) {
                this.fixErrorField('captcha_code', {
                    fieldsName: 'captcha_code',
                    fieldsError: '请输入验证码',
                });
            } else if (!fieldsValue['smscode']) {
                this.fixErrorField('smscode', {
                    fieldsName: 'smscode',
                    fieldsError: '请输入短信验证码',
                });
            }

            if (JSON.stringify(fieldsError) === '{}') {
                dispatch({
                    type: 'submitLogin',
                    loginType: 0, // 登录类型 0 手机号 1 账密
                    payload: {
                        mobile: fieldsValue['phone'],
                        smscode: fieldsValue['smscode'],
                    },
                });
            }
        }
    };

    static getDerivedStateFromProps(props, state) {
        if (props.current !== state.current) {
            return {
                ...state,
                current: props.current,
                fieldsValue: {},
                fieldsError: {},
                error: '',
            };
        }
    }

    render() {
        const { current } = this.props;
        const { fieldsError, fieldsValue } = this.state;

        const config = {
            onChange: this.handleChange,
            onBlur: this.handleChange,
            onError: this.handleError,
            clearErrors: this.clearErrors,
            fieldsError,
            fieldsValue,
        };

        return (
            <Fragment>
                <form className={styles.formGroup}>
                    {current === 1 ? (
                        <Fragment>
                            <FormItem
                                {...config}
                                name="account"
                                placeholder="用户名/邮箱/手机号"
                                labelName="账号"
                                validateTrigger="blur"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入用户名/邮箱/手机号',
                                    },
                                ]}>
                                <InputItem />
                            </FormItem>
                            <FormItem
                                {...config}
                                name="password"
                                placeholder="登录密码"
                                labelName="密码"
                                validateTrigger="blur"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入密码',
                                    },
                                    {
                                        pattern: /^\S{6,20}$/,
                                        message: '密码在6~20位',
                                    },
                                ]}>
                                <InputItem inputType="password" />
                            </FormItem>
                            <FormItem
                                {...config}
                                name={'captcha_code'}
                                placeholder="图片验证码"
                                labelName="验证码"
                                validateTrigger={'blur'}
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入验证码!',
                                    },
                                ]}>
                                <InputItem inputType="captcha_code" />
                            </FormItem>
                        </Fragment>
                    ) : null}
                    {current === 2 ? (
                        <Fragment>
                            <FormItem
                                {...config}
                                name="phone"
                                placeholder="请输入手机号"
                                labelName="手机号"
                                validateTrigger="blur"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入11位手机号',
                                    },
                                    {
                                        pattern: /^1\d{10}$/,
                                        message: '请填写正确的手机号',
                                    },
                                ]}>
                                <InputItem labelSeparete />
                            </FormItem>
                            <FormItem
                                {...config}
                                name="captcha_code"
                                placeholder="图形验证码"
                                validateTrigger="blur"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入验证码',
                                    },
                                    {
                                        pattern: /^[a-zA-Z0-9]{6}$/,
                                        message: '验证码错误',
                                    },
                                ]}>
                                <InputItem />
                            </FormItem>
                            <FormItem
                                {...config}
                                name="smscode"
                                placeholder="短信验证码"
                                validateTrigger="blur"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入短信验证码',
                                    },
                                ]}>
                                <InputItem getMsgCode={this.getMsgCode} />
                            </FormItem>
                        </Fragment>
                    ) : null}
                    <LoginContext.Consumer>
                        {context => (
                            <Fragment>
                                <div
                                    dangerouslySetInnerHTML={{ __html: context.register.join('') }}
                                    className={styles.otherOption}
                                />
                                <div
                                    className={styles.loginBtn}
                                    onClick={() => this.submitLogin(context.dispatch, context.captchaId)}
                                    style={{
                                        backgroundColor: context.submitBtnBgColor,
                                        color: context.submitBtnColor,
                                    }}>
                                    {context.login_button_text}
                                </div>
                            </Fragment>
                        )}
                    </LoginContext.Consumer>
                </form>
            </Fragment>
        );
    }
}

export default errorBoundary(InputGroup);
