import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { cookie } from '@ifeng-fe/ui_base';
import LoginContext from './loginContext';
import { pagesConfig } from '../util';
import { getVerificationCode, sendSms, loginPost, cacheUserInfo } from '../../../services/api';

class LoginProvider extends PureComponent {
    static propTypes = {
        children: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);

        const parsed = queryString.parse(window.location.search);

        const typeId = parsed.id || '0';

        this.typeId = parsed.id;

        this.callback = parsed.cb || 'http://www.ifeng.com';

        this.pageConfig = pagesConfig[`${typeId}`] || pagesConfig['0'];

        this.smsInterval = null;

        const defaultPageConfig = {
            register: [],
            login3: [],
            login_button_text: '立即登录',
            submitBtnBgColor: '#f54343', // 提交按钮背景颜色
            submitBtnColor: '#fff', // 提交按钮文字颜色
        };

        this.state = {
            ...defaultPageConfig,
            ...this.pageConfig,
            currentLoginTab: this.pageConfig.login_tab_first || 1,
            captchaId: '',
            captchaImg: '',
            smsSendStatus: false,
            hasSendSms: false,
            smsInterval: 60,
        };
    }

    componentDidMount = async () => {
        await this.refreshCaptchaImg();
    };

    refreshCaptchaImg = async () => {
        const captchaRes = await getVerificationCode();

        if (captchaRes) {
            this.setState({
                captchaImg: captchaRes.image,
                captchaId: captchaRes.id,
            });
        }
    };

    handleContentChange = async action => {
        const { type } = action;

        switch (type) {
            case 'changeTab':
                // if (action.payload === 2 && !this.state.captchaImg) {
                await this.refreshCaptchaImg();
                // }

                return this.setState({
                    currentLoginTab: action.payload,
                });
            case 'refreshCaptchaImg':
                return await this.refreshCaptchaImg();
            case 'sendSms':
                await sendSms(
                    action.payload,
                    successRes => {
                        if (successRes && successRes.smscode_len) {
                            if (this.smsInterval) {
                                clearInterval(this.smsInterval);
                            }

                            return this.setState(
                                {
                                    smsSendStatus: true,
                                    hasSendSms: true,
                                },
                                () => {
                                    this.smsInterval = setInterval(() => {
                                        this.setState(
                                            {
                                                smsInterval: this.state.smsInterval - 1,
                                            },
                                            () => {
                                                if (this.state.smsInterval === 0) {
                                                    this.setState({
                                                        smsSendStatus: false,
                                                        smsInterval: 60,
                                                    });
                                                    clearInterval(this.smsInterval);
                                                }
                                            },
                                        );
                                    }, 1000);
                                },
                            );
                        }
                    },
                    errorRes => {
                        if (action.errorCallback) {
                            action.errorCallback(errorRes);
                        }

                        return this.refreshCaptchaImg();
                    },
                );
                break;
            case 'submitLogin':
                await loginPost(
                    action.loginType,
                    action.payload,
                    successRes => {
                        cacheUserInfo(userInfo => {
                            // console.log(successRes, userInfo);
                            setTimeout(() => {
                                if (!userInfo.realname_mobile) {
                                    window.location.href = `${location.origin}/realName?id=${this.typeId ||
                                        0}&cb=${encodeURIComponent(this.callback)}`;
                                } else {
                                    window.location.href = this.callback;
                                }
                            }, 100);
                        });
                    },
                    errorRes => {
                        if (action.errorCallback) {
                            action.errorCallback(errorRes);
                        }
                    },
                );
                break;
        }
    };

    async componentDidMount() {
        if (this.pageConfig.pageBgColor) {
            document.body.style.backgroundColor = this.pageConfig.pageBgColor;
        }

        const sid = cookie.get('sid');

        if (sid) {
            window.location.href = this.callback;
        } else {
            const { login_tab_first } = this.pageConfig;

            if (login_tab_first === 2) {
                // 初始化获取图形验证码
                await this.refreshCaptchaImg();
            }
        }
    }

    render() {
        return (
            <LoginContext.Provider
                value={{ dispatch: this.handleContentChange, ...this.state, callback: this.callback }}>
                {this.props.children}
            </LoginContext.Provider>
        );
    }
}

export default LoginProvider;
