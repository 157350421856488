import { restAjax, cookie } from '@ifeng-fe/ui_base';

// export const USERAPI = 'https://user.ifeng.com';
// eslint-disable-next-line no-undef
export const USERAPI = InterfaceDomain;

/**
 * 获取图片验证码
 */
const getVerificationCode = async () => {
    try {
        const res = await restAjax(`${USERAPI}/api/v1/get/captcha`, {
            type: 'get',
            query: { type: 2, platform: 'w', systemid: 1 },
            cache: new Date().getTime(), // 取消ie自动缓存
        });

        return res;
    } catch (e) {
        // const { tips } = e;

        console.error(e);
        // alert(tips);
    }
};

/**
 * 发送短信验证码
 * @param {Object} params mobile 手机号 smsform 短信形式 captcha_id 图片验证码id captcha_code 图片验证码
 * @param {Function} failCallback 异常回调
 * 发送短信的次数超过指定次数后，会返回错误码CaptchaRequired，收到该错误码后需要调用获取图片验证码接口显示图片验证码，下次发送短信时需要把图片验证码通过captcha_id和captcha_code两个参数传递过来。
 */
const sendSms = async (params, successCallback, failCallback) => {
    try {
        const res = await restAjax(`${USERAPI}/api/v1/sendsms`, {
            type: 'post',
            query: {
                platform: 'w',
                systemid: 1,
            },
            body: { ...params },
            cache: false,
        });

        if (successCallback) {
            successCallback(res);
        }
    } catch (e) {
        console.error(e);

        if (failCallback) {
            failCallback(e);
        }
    }
};

/**
 * 找回密码时，发送短信验证码
 * @param {Object} params mobile 手机号 smsform 短信形式 captcha_id 图片验证码id captcha_code 图片验证码
 * @param {Function} failCallback 异常回调
 * 发送短信的次数超过指定次数后，会返回错误码CaptchaRequired，收到该错误码后需要调用获取图片验证码接口显示图片验证码，下次发送短信时需要把图片验证码通过captcha_id和captcha_code两个参数传递过来。
 */
const sendsmsbyaccount = async (params, successCallback, failCallback) => {
    try {
        const res = await restAjax(`${USERAPI}/api/v1/sendsmsbyaccount`, {
            type: 'post',
            query: {
                platform: 'w',
                systemid: 1,
            },
            body: { ...params },
            cache: false,
        });

        if (successCallback) {
            successCallback(res);
        }
    } catch (e) {
        console.error(e);

        if (failCallback) {
            failCallback(e);
        }
    }
};

/**
 * 登录请求
 * @param {Number} type 登录类型 0 手机号 1 账密
 * @param {Object} params 请求参数
 */
const loginPost = async (type, params, successCallback, failCallbak) => {
    try {
        const postUrls = [`${USERAPI}/api/v1/login/mobilesms`, `${USERAPI}/api/v1/login/commonlite`];

        const res = await restAjax(postUrls[type], {
            type: 'post',
            query: {
                platform: 'w', // web
                systemid: 1, // 凤凰通
            },
            body: {
                ...params,
            },
            cache: false,
            corsCookie: true,
        });

        if (successCallback) {
            successCallback(res);
        }
    } catch (e) {
        if (failCallbak) {
            failCallbak(e);
        }
    }
};

/**
 * 绑定手机号
 * @param {Objetc} params mobile sms auth_token
 * @param {*} failCallback 失败的回调
 */
const bindMobile = async (params, successCallback, failCallback) => {
    try {
        const res = await restAjax(`${USERAPI}/api/v1/bind/mobile`, {
            type: 'post',
            query: {
                platform: 'w', // web
                systemid: 1, // 凤凰通
            },
            body: {
                ...params,
            },
            corsCookie: true,
            cache: false,
        });

        if (successCallback) {
            successCallback(res);
        }
    } catch (e) {
        if (failCallback) {
            failCallback(e);
        }
    }
};

/**
 * 手机号实名认证
 * @param {Object} params mobile sms auth_token
 * @param {Function} successCallback 成功后的回调
 * @param {Function} failCallback 失败的回调
 */
const realNameMobile = async (params, successCallback, failCallback) => {
    try {
        const res = await restAjax(`${USERAPI}/api/v1/bind/realnamemobile`, {
            type: 'post',
            query: {
                platform: 'w', // web
                systemid: 1, // 凤凰通
            },
            body: {
                ...params,
            },
            corsCookie: true,
            cache: false,
        });

        if (successCallback) {
            successCallback(res);
        }
    } catch (e) {
        if (failCallback) {
            failCallback(e);
        }
    }
};

/**
 * 获取用户信息
 */
const cacheUserInfo = async (successCallback, errorCallback) => {
    try {
        const res = await restAjax(`${USERAPI}/api/v1/get/userinfobytoken`, {
            query: {
                platform: 'w', // web
                systemid: 1, // 凤凰通
            },
            cache: false,
            corsCookie: true,
        });

        if (res) {
            const { realname_mobile, nickname, username } = res;
            const sid = cookie.get('sid');

            if (username || nickname) {
                cookie.set('IF_USER', username || nickname || sid, '/', '.ifeng.com');
            }

            if (realname_mobile) {
                cookie.set('IF_REAL', '0', '/', '.ifeng.com');
            } else {
                cookie.set('IF_REAL', '1', '/', '.ifeng.com');
            }
        }

        if (successCallback) {
            successCallback(res);
        }
    } catch (e) {
        console.error(e);
        if (errorCallback) {
            errorCallback(e);
        }
    }
};

/**
 * 检查手机号是否存在
 * @param {Number} mobile 手机号
 * @param {Function} successCallback 成功回调
 * @param {Function} failCallbak 失败回调
 */
const checkMobile = async (mobile, successCallback, failCallbak) => {
    try {
        const res = await restAjax(`${USERAPI}/api/v1/check/mobile`, {
            query: {
                mobile,
                platform: 'w', // web
                systemid: 1, // 凤凰通
            },
            cache: false,
            corsCookie: true,
        });

        if (successCallback) {
            successCallback(res);
        }
    } catch (e) {
        if (failCallbak) {
            failCallbak(e);
        }
    }
};

/**
 * 手机号注册
 * @param {Object} params mobile 手机号 smscode 短信验证码 password 明文密码
 * @param {Function} successCallback 成功回调
 * @param {Function} failCallback 失败/异常 回调
 */
const registerPost = async (params, successCallback, failCallback) => {
    try {
        const res = await restAjax(`${USERAPI}/api/v1/register/mobilesms`, {
            type: 'post',
            query: {
                platform: 'w',
                systemid: 1,
            },
            body: { ...params },
            cache: false, // 取消ie自动缓存
        });

        if (successCallback) {
            successCallback(res);
        }
    } catch (e) {
        if (failCallback) {
            failCallback(e);
        }
    }
};

/**
 * 校验短信验证码 绑定手机号时使用
 * @param {Object} params { mobile手机号, smscode短信验证码 }
 * @param {*} failCallback 失败的回调
 */
const checkSms = async (params, successCallback, failCallback) => {
    try {
        const res = await restAjax(`${USERAPI}/api/v1/check/mobilesms`, {
            type: 'get',
            query: {
                platform: 'w',
                systemid: 1,
                ...params,
            },
            cache: false, // 取消ie自动缓存
        });

        if (successCallback) {
            successCallback(res);
        }
    } catch (e) {
        const { code, tips } = e;

        failCallback({ code, tips });
        console.error(e);
    }
};

/**
 * 手机找回密码
 * @param {*} params
 */
const findpwd = async (params, failCallback) => {
    try {
        const res = await restAjax(`${USERAPI}/api/v1/pwd/reset/mobilesms`, {
            type: 'post',
            query: {
                platform: 'w', // web
                systemid: 1, // 凤凰通
            },
            body: {
                ...params,
            },
            corsCookie: true,
            cache: false,
        });

        return res;
    } catch (error) {
        const { code, tips } = error;

        failCallback({ code, tips });
        console.error(error);
    }
};

export {
    getVerificationCode,
    sendSms,
    sendsmsbyaccount,
    loginPost,
    cacheUserInfo,
    checkMobile,
    registerPost,
    bindMobile,
    realNameMobile,
    checkSms,
    findpwd,
};
