import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';

import { USERAPI } from '../../../services/api';

const linksUrl = `${USERAPI}/api/v1/get/authorizeurl`;

class OtherLogin extends PureComponent {
    static propTypes = {
        content: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        callback: PropTypes.string,
    };

    renderOtherLogin = () => {
        const { content, callback } = this.props;

        const defaultLogin3Map = {
            qq: `<a class="qzone_btn" href="${linksUrl}?sns=qzone&platform=w&systemid=13&callback=${callback}"><img src="https://p2.ifengimg.com/a/2017/1120/qq.png" alt="QQ登录"></a>`,
            weibo: `<a class="wb_btn" href="${linksUrl}?sns=sina&platform=w&systemid=13&callback=${callback}"><img src="https://p0.ifengimg.com/a/2017/1120/sina.png" alt="微博登陆"></a>`,
            weixin: `<a class="wx_btn bg_weixin" href="${linksUrl}?sns=weixin&platform=w&systemid=13&callback=${callback}"><img src="//id.ifeng.com/static/m/images/weixin.png" alt="微信登陆"></a>`,
        };

        if (Object.prototype.toString.call(content) === '[object Object]') {
            const renderLogin3 = [];

            Object.keys(content).forEach(itemLogin3 => renderLogin3.push(content[itemLogin3]));

            return renderLogin3.join('');
        } else if (Object.prototype.toString.call(content) === '[object Array]') {
            const renderLogin3 = [];

            content.forEach(itemLogin3 => renderLogin3.push(defaultLogin3Map[itemLogin3]));

            return renderLogin3.join('');
        }

        return '';
    };

    render() {
        const { content } = this.props;

        return (
            <Fragment>
                {Object.prototype.toString.call(content) === '[object Array]' && content.length === 0 ? null : (
                    <div className={styles.moreaccount}>
                        <div>&nbsp;&nbsp;</div>
                        <p>第三方账号登录</p>
                    </div>
                )}
                <div className={styles.otherlogin} dangerouslySetInnerHTML={{ __html: this.renderOtherLogin() }} />
            </Fragment>
        );
    }
}

export default errorBoundary(OtherLogin);
