import React, { PureComponent, Fragment } from 'react';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import Logo from './logo/';
import Tabs from './tabs/';
import InputGroup from './inputGroup/';
import LoginProvider from './store/loginProvider';
import LoginContext from './store/loginContext';
import OtherLogin from './otherLogin';

class Layout extends PureComponent {
    render() {
        return (
            <div className={styles.container}>
                <div className={styles.ad_header} />
                <LoginProvider>
                    <section className={styles.main}>
                        <LoginContext.Consumer>
                            {context => (
                                <Fragment>
                                    <Logo content={context} />
                                    {context.hide_login_tab ? null : (
                                        <Tabs
                                            config={context}
                                            current={context.currentLoginTab}
                                            handleChange={context.dispatch}
                                        />
                                    )}
                                    <InputGroup current={context.currentLoginTab} />
                                </Fragment>
                            )}
                        </LoginContext.Consumer>
                    </section>
                    <LoginContext.Consumer>
                        {context => <OtherLogin content={context.login3} callback={context.callback} />}
                    </LoginContext.Consumer>
                </LoginProvider>
            </div>
        );
    }
}

export default errorBoundary(Layout);
